import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React from 'react'
import gradient from 'gradient-color'
import { theme } from 'src/styling'
import sha1 from 'sha1'
import { recordUserInteraction, userToggledLegendOptionMostDetectedTechnologies } from 'src/services/posthog'

// ===================
// TYPES
// ===================
// test comment
interface Props {
  colorScheme?: 'severity' | 'custom'
  data: DataItem[]
  endColor?: string
  height?: number | string
  legend?: boolean
  startColor?: string
  width?: number
  size?: string
  innerSize?: string
  spacingLeft?: number
  handleClickPoint?: (options: any) => void
}

interface DataItem {
  name: string
  y: number
}

interface Event {
  id: number
  name: string
  selected: boolean
  sliced: boolean
  type: string
  y: number
}

// ===================
// UTILITIES & OPTIONS
// ===================

const pieColors = (props: Props): string[] => {
  if (props.colorScheme === 'custom') {
    if (props.data.length > 2) return gradient([props.startColor, props.endColor], props.data.length)
    return [props.startColor, props.endColor]
  } else if (props.colorScheme === 'severity') {
    const minMaxValues = props.data.reduce(
      (minMax, { y }) => {
        if (y < minMax.min) {
          minMax.min = y
        }
        if (y > minMax.max) {
          return { ...minMax, max: y }
        }
        return minMax
      },
      { min: 1000, max: 0 }
    )

    const colors = props.data.map(({ y }) => {
      let colorComparisonValue = ((y - minMaxValues.min) / (minMaxValues.max - minMaxValues.min)) * 10

      colorComparisonValue = isNaN(colorComparisonValue) ? 0 : colorComparisonValue
      if (colorComparisonValue > 10) {
        colorComparisonValue = 10
      } else if (colorComparisonValue < 0) {
        colorComparisonValue = 0
      }

      const pointColor = theme.colors.severity
        .find((_, index) => {
          return index <= colorComparisonValue && index + 1 >= colorComparisonValue
        })
        .replace(', 1)', ', 0.8)')

      return pointColor
    })

    return colors
  }
}

const generateConfig = (props: Props): object => {
  return {
    chart: {
      animation: false,
      height: props.height,
      width: props.width,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      style: { fontFamily: 'Roboto, sans-serif' },
      type: 'pie',
      spacingLeft: props.spacingLeft,
      spacingTop: 0,
      spacingBottom: 0,
      spacingRight: 0,
    },
    credits: {
      enabled: false,
    },
    legend: {
      layout: 'vertical',
      align: 'left',
      verticalAlign: 'middle',
      itemMarginTop: 9,
      itemMarginBottom: 9,
      enabled: props.legend,
      itemStyle: {
        fontSize: theme.fontSizes.medium,
      },
      navigation: {
        arrowSize: 10,
        style: {
          fontSize: theme.fontSizes.medium,
        },
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        colors: props.data.length > 0 && pieColors(props),
        cursor: props?.handleClickPoint ? 'pointer' : 'default',
        innerSize: props.innerSize,
        size: props.size,
        events: {
          click: (event: { point: { options: Event } }): void => {
            props.handleClickPoint(event.point.options)
          },
        },
        point: {
          events: {
            legendItemClick: () => recordUserInteraction(userToggledLegendOptionMostDetectedTechnologies),
          },
        },
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        states: {
          hover: {
            brightness: 0.15,
            halo: {
              size: 7,
              opacity: 0.15,
            },
          },
        },
      },
    },
    series: [
      {
        colorByPoint: true,
        data: props.data,
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    ],
    title: {
      text: null,
    },
    tooltip: {
      backgroundColor: theme.colors.ui.blueDark,
      borderColor: theme.colors.ui.blueDark,
      headerFormat: '<span></span>',
      pointFormat:
        "<span><b>{point.name}</b></span><br/><span style='font-weight: 400;'>{point.type}: {point.y}</span><br/>",
      shadow: true,
      style: { color: theme.colors.ui.white, fontSize: theme.fontSizes.mediumLarge },
      xDateFormat: '%d-%m-%Y',
      padding: 14,
    },
  }
}

// ===================
// COMPONENT
// ===================
const GlobalPieChart: React.FC<Props> = (props) => {
  const config = generateConfig(props)

  return <HighchartsReact key={sha1(JSON.stringify(props.data))} highcharts={Highcharts} options={config} />
}

GlobalPieChart.defaultProps = {
  colorScheme: null,
  data: null,
  endColor: null,
  height: null,
  legend: false,
  startColor: null,
  width: null,
  size: null,
  innerSize: null,
  spacingLeft: null,
}

export { GlobalPieChart }
