import React, { ReactNode, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, List, ListItem, ListItemText, Chip, Divider, Theme } from '@material-ui/core'
import { theme } from 'src/styling'
import {
  recordUserInteraction,
  userClickedOnModalSourceLink,
  userClickedOnModalChip,
  userClickedOnReportViewerChip,
  reportViewerUserTriedToClickOnChipLink,
} from 'src/services/posthog'
import { getDateRangeDefaultParam } from 'src/utils/filters'
import ReactTooltip from 'react-tooltip'

// ===================
// TYPES
// ===================
type ChipSectionProps = {
  edges: any
  edgesType?: string
  primaryText?: string
  modalType?: string
  labelKey?: string
  id?: string
  cvssVersion?: string
  hideDivider?: boolean
  showAllChips?: boolean
  intelligencePageAccess?: boolean
  iocsAccessDisabled?: boolean
}

interface StyleProps {
  modalType: string
  intelligencePageAccess: boolean
  iocsAccessDisabled: boolean
}

interface ChipComponentProps {
  title: string
  listItemComponent: ReactNode
  rawList?: any[]
}

interface CveListItemProps {
  data: any
  title: string
}

// ===================
// STYLES
// ===================
const useStyles = makeStyles<Theme, StyleProps>({
  chip: ({ modalType, intelligencePageAccess, iocsAccessDisabled }) => ({
    margin: '2px',
    filter: iocsAccessDisabled ? 'blur(5px)' : 'none',
    pointerEvents: iocsAccessDisabled ? 'none' : 'auto',
    cursor:
      (modalType === 'report-viewer' && !intelligencePageAccess) || iocsAccessDisabled ? 'not-allowed' : 'pointer',
    fontSize: theme.fontSizes.mediumSmall,
    height: '30px',
  }),
  chipNoClick: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
    '&:focus': {
      backgroundColor: '#e0e0e0',
    },
  },
  listTitle: {
    '& .MuiTypography-body1': {
      fontSize: theme.fontSizes.mediumLarge,
      lineHeight: '1.35',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  listContainer: ({ modalType }) => ({
    padding: modalType === 'company' || modalType === 'report-viewer' ? '0 27px' : modalType === 'cve' ? '7px 0' : '0',
  }),
  link: {
    color: theme.colors.ui.grey,
    wordBreak: 'break-all',
    textDecoration: 'none',
    borderBottom: 'none',
    '&:hover': {
      color: theme.colors.ui.greyLight,
    },
  },
  showMore: {
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 0',
    fontSize: theme.fontSizes.mediumLarge,
  },
})

const ChipSection: React.FC<ChipSectionProps> = ({
  edges,
  edgesType,
  primaryText,
  modalType,
  labelKey,
  id,
  cvssVersion,
  hideDivider,
  showAllChips,
  intelligencePageAccess,
  iocsAccessDisabled,
}) => {
  const classes = useStyles({ modalType, intelligencePageAccess, iocsAccessDisabled })
  const cveSectionTitles = ['Access Vector', 'Access Complexity', 'Authentication', 'Vendor', 'Software Affected']

  const handleOnClick = (node) => {
    if (
      modalType === 'company' ||
      modalType === 'report' ||
      modalType === 'malware' ||
      (modalType === 'report-viewer' && intelligencePageAccess)
    ) {
      if (modalType === 'report-viewer') recordUserInteraction(userClickedOnReportViewerChip(primaryText))
      if (!(modalType === 'report-viewer')) recordUserInteraction(userClickedOnModalChip(primaryText))
      const tagId = node.tagId || node.tag?.id || (edgesType === 'sectorTags' && node.id)
      const isIocFilter = node.malwareSample || node.domain || node.host
      const dateRange = getDateRangeDefaultParam()

      const filter = []
      if (isIocFilter) {
        filter.push(`${dateRange}&ioc=${labelKey === 'host' ? 'ip' : labelKey},${node[labelKey]}`)
      } else {
        tagId
          ? filter.push(`${dateRange}&tag=${tagId},${node[labelKey]}`)
          : filter.push(`${dateRange}&tag=${node[labelKey]}`)
      }
      filter.join('&')

      window.open(`/intelligence?${filter}`)
    }
    if (modalType === 'report-viewer' && !intelligencePageAccess)
      recordUserInteraction(reportViewerUserTriedToClickOnChipLink)
  }

  const ChipComponent = ({ title, listItemComponent }: ChipComponentProps) => {
    const [expand, setExpand] = useState(showAllChips)

    const listItemComponentFormatted = Array.isArray(listItemComponent) ? listItemComponent : [listItemComponent]
    return (
      <>
        <ListItem className={classes.listTitle} id={id}>
          <Grid container>
            <Grid item md={6} xs={12} container direction="row" justifyContent="center" alignItems="center">
              <ListItemText primary={title} />
            </Grid>
            <Grid item md={6} xs={12} container direction="row" justifyContent="center" alignItems="center">
              <ListItemText primary={expand ? listItemComponentFormatted : listItemComponentFormatted.slice(0, 10)} />
            </Grid>

            {!showAllChips && listItemComponentFormatted?.length > 10 && !iocsAccessDisabled && !iocsAccessDisabled && (
              <div className={classes.showMore} onClick={() => setExpand(!expand)} id={'show-more-div'}>
                {expand ? 'Show less' : 'Show more'}
              </div>
            )}
          </Grid>
        </ListItem>

        {!hideDivider && <Divider light />}
      </>
    )
  }

  const CveListItem = ({ data, title }: CveListItemProps) => {
    const nvd = data?.nvd
    const nvdVulnerableEdges = data?.nvd?.nvdVulnerables.edges

    if (nvd?.accessVector && title === 'Access Vector')
      return (
        <ChipComponent
          title={title}
          listItemComponent={<Chip className={`${classes.chip} ${classes.chipNoClick}`} label={nvd.accessVector} />}
        />
      )
    if (nvd?.accessComplexity && title === 'Access Complexity')
      return (
        <ChipComponent
          title={title}
          listItemComponent={<Chip className={`${classes.chip} ${classes.chipNoClick}`} label={nvd.accessComplexity} />}
        />
      )
    if (nvd?.authentication && title === 'Authentication' && cvssVersion === 'V2')
      return (
        <ChipComponent
          title={title}
          listItemComponent={<Chip className={`${classes.chip} ${classes.chipNoClick}`} label={nvd.authentication} />}
        />
      )

    if (nvdVulnerableEdges && (title === 'Software Affected' || title === 'Vendor')) {
      const uniqueVendor = Array.from(
        new Set(nvdVulnerableEdges.map((nvdVulnerable: any) => nvdVulnerable.node.cpeV23.vendor))
      ).map((cpe: any) => nvdVulnerableEdges.find((node: any) => node.node.cpeV23.vendor === cpe))
      return (
        (nvdVulnerableEdges.length > 0 || uniqueVendor.length > 0) && (
          <ChipComponent
            title={title}
            listItemComponent={
              title === 'Software Affected'
                ? nvdVulnerableEdges.map((node: any) => (
                    <Chip className={`${classes.chip} ${classes.chipNoClick}`} label={node.node.cpeV23.software} />
                  ))
                : uniqueVendor.map((node: any) => (
                    <Chip className={`${classes.chip} ${classes.chipNoClick}`} label={node.node.cpeV23.vendor} />
                  ))
            }
          />
        )
      )
    }

    return <></>
  }

  return modalType === 'cve' ? (
    <List component="nav" aria-label="mailbox folders" className={classes.listContainer}>
      {cveSectionTitles.map((title) => (
        <CveListItem key={title} data={edges} title={title} />
      ))}
    </List>
  ) : (
    <List component="nav" aria-label="mailbox folders" className={classes.listContainer}>
      <ChipComponent
        title={primaryText}
        rawList={edges}
        listItemComponent={edges.map(({ node }) => {
          return labelKey === 'sources' ? (
            <div key={node.id}>
              <a
                className={classes.link}
                key={node.id}
                href={node.source}
                onClick={() => recordUserInteraction(userClickedOnModalSourceLink)}
                target="_blank"
                rel="noreferrer"
              >
                {node.source}
              </a>
            </div>
          ) : (
            <Chip
              onClick={() => handleOnClick(node)}
              className={classes.chip}
              key={node.id}
              label={node[labelKey]}
              data-tip=""
            />
          )
        })}
      />
      {modalType === 'report-viewer' && !intelligencePageAccess && (
        <ReactTooltip backgroundColor={theme.colors.ui.blueDark}>
          Please upgrade your subscription to access this page
        </ReactTooltip>
      )}
    </List>
  )
}

export default ChipSection
