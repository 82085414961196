import 'core-js/stable'

import { GlobalStyles, theme } from './styling'
import { persistor, rootStore } from './redux/store'

import { ApolloProvider } from '@apollo/react-hooks'
import App from './app'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/core/styles'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import SnackBar from './context/snackbar'
import { ThemeProvider } from 'emotion-theming'
import client from './network/apollo-client'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  person_profiles: 'identified_only',
})

const root = document.querySelector('#root')

// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render')
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   })
// }

if (root) {
  ReactDOM.render(
    <Provider store={rootStore}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ApolloProvider client={client}>
            <GlobalStyles>
              <MaterialThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                  <SnackBar.Provider>
                    <PostHogProvider client={posthog}>
                      <App />
                    </PostHogProvider>
                  </SnackBar.Provider>
                </ThemeProvider>
              </MaterialThemeProvider>
            </GlobalStyles>
          </ApolloProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>,
    root
  )
}
