import React, { useEffect, useState } from 'react'
import { gql as GQL } from 'apollo-boost'
import { ModalProps, SectionInterface } from 'components'
import { Dialog, DialogSection } from 'src/components/dialogs'
import client from 'src/network/apollo-client'
import { createResource } from 'src/network/fetcher'
import { useHistory } from 'react-router-dom'
import Error from 'src/components/errors/error'

// ===================
// QUERIES & RESOURCES
// ===================
const COMPANY_PRIVACY_DATA = GQL`
query queryCompanyPrivacyData($first: Int, $after:Int, $withStatus: Boolean, $types: [TypeEnum!], $domains:[String]){
  data1: companyPrivacyDataAll(first:$first, after:$after,withStatus: $withStatus, types: $types, domains: $domains) {
    totalCount
    pageInfo{
      hasNextPage
    }
    edges {
      node {
        id
        domain
        fullPath
        provider
        insertTimestamp
        uniqueHash
        type
        userItem {
          id
          note
          userItemStatus {
            id
            description
          }
        }

      }
    }
  }
  totalCountWithStatuses: companyPrivacyDataAll(withStatus:true, types:$types,domains: $domains){
    totalCount
  }
  totalCountWithoutStatuses:  companyPrivacyDataAll(withStatus:false, types:$types, domains: $domains){
    totalCount
  }
}
`
const COMPANY_PRIVACY_DATA_FOR_TYPE_AND_STATUS = GQL`
query companyPrivacyDataForEachType($withStatus: Boolean, $type: TypeEnum!){
  companyPrivacyData(withStatus: $withStatus, type:$type) {
  totalCount
  }
}
`
const QUERY_ROOT_DOMAINS = GQL`
  query companyRootDomains($filter: GlobalFilterInput) {
    data: companyRootDomainsAutoComplete(filter: $filter) {
          domains
      }
    }`

const QUERY_ORGANISATION_DRP_ACCESS = GQL`
query organisation{
  organisation {
    prpAccessEnabled
    drpAccessEnabled
  }
}
`

export const mapType: { key: string; title: string }[] = [
  { key: 'ads', title: 'Ad Trackers' },
  { key: 'canvas', title: 'Canvas Fingerprinting' },
  { key: 'cookies', title: 'Third-Party Cookies' },
  { key: 'keyloggers', title: 'Key Logging' },
  { key: 'sessRec', title: 'Session Recording' },
  { key: 'trackingPixel', title: 'Tracking Pixels' },
]

const DialogSectionWithPermission = ({ resource, sections, props }) => {
  const history = useHistory()

  const { organisation } = resource.read()
  if (organisation?.drpAccessEnabled === 0)
    return (
      <Dialog onClose={(): void => history.push('/external-asm')}>
        <Error errorCode="FORBIDDEN" />
      </Dialog>
    )

  return <DialogSection {...props} sections={sections} />
}

//Wrapper
const CompanyPrivacyData: React.FC<ModalProps> = (props) => {
  const [chipsData, setChipsData] = useState<{ title: string; totalCount: number }[]>([])
  const [error, setError] = useState<any>(null)

  const urlParams = location.search
  const params = new URLSearchParams(urlParams)
  const isActionedTable = params.get('actionedtable')
  const resource = createResource(QUERY_ORGANISATION_DRP_ACCESS)
  const fetchData = async () => {
    setError(null)

    try {
      const promises = mapType.map((type) => {
        return client
          .query({
            query: COMPANY_PRIVACY_DATA_FOR_TYPE_AND_STATUS,
            variables: { withStatus: isActionedTable ? true : false, type: type.key },
          })
          .then(({ data }) => ({
            title: type.title,
            key: type.key,
            totalCount: data?.companyPrivacyData?.totalCount || 0,
          }))
      })
      const results = await Promise.all(promises)

      setChipsData(results)
    } catch (err) {
      setError(err)
    }
  }
  useEffect(() => {
    fetchData()
  }, [client, isActionedTable])

  const handleRefetch = () => {
    fetchData()
  }

  const contentDrawer: { header: string; description: string }[] = [
    {
      header: 'Ad Trackers',
      description:
        'Ad trackers are technologies that identify and collect information about users. They are used to collect website user analytics for ad-targeting, and by data brokers and other information collectors to build user profiles.',
    },
    {
      header: 'Canvas Fingerprinting',
      description: `Canvas fingerprinting is used to identify a user's browser even if the user has blocked cookies.`,
    },
    {
      header: 'Third-Party Cookies',
      description: `Third party cookies are stored in the web browser when visiting a website. These cookies are used to identify a user when visiting other websites that use the same cookie provider. They are used to build data on a user and surface customised ads.`,
    },
    {
      header: 'Key Logging',
      description: `Key loggers capture all information typed by the user even before they choose to press the submit button. This technique has been used to identify anonymous web users by matching them to postal addresses and real names.`,
    },
    {
      header: 'Session Recording',
      description: `Session recorders monitor and record all of a user's behavior on a webpage like scrolling, mouse clicks etc.`,
    },
    {
      header: 'Tracking Pixels',
      description: `Tracking pixels are a snippet of code that sends data back to the provider about people who visit this site and allows the site operator to later target them with ads on a platform owned by the provider.`,
    },
  ]

  const sections: SectionInterface[] = [
    {
      title: `{totalCount} Privacy Item{isPlural} Found`,
      headings: ['Domain', 'Type', 'Provider', 'Location'],
      rowKeys: ['node.domain', 'node.type', 'node.provider', 'node.fullPath'],
      alias: 'data1',
      download: !props?.filter?.country ? 'privacyData' : null,
      query: COMPANY_PRIVACY_DATA,
      createUserItem: props.createUserItem,
      deleteUserItems: props.deleteUserItems,
      enableFilter: true,
      filterQuery: QUERY_ROOT_DOMAINS,
      filterPlaceholder: 'Search domains',
      chipsData: chipsData,
      contentDrawer: contentDrawer,
      contentSentence: 'Click here for more information',
      handleRefetch: handleRefetch,
    },
  ]

  return <DialogSectionWithPermission resource={resource} props={props} sections={sections} />
}

export default CompanyPrivacyData
