import React, { useState, useEffect } from 'react'
import { theme } from 'src/styling'
import { Box, Button, TextField, Typography, makeStyles } from '@material-ui/core'
import OrpheusLogoWithBlueText from 'src/images/orpheus/orpheus-logo-with-blue-text.png'
import { Loaders } from 'components'

// ======
// TYPES
// ======
type CodeEmailSubmitPageProps = {
  urlCode: string
  userEnteredCodeError: string
  email: string
  handleSetEmail: (email: string) => void
  emailError: string
  orgNameError: string
  orgDomainError: string
  loadingCustomerCouponInfo: boolean
  handleSubmitCodeAndEmail: (
    userEnteredCode: string,
    email: string,
    orgName: string,
    orgDomain: string
  ) => Promise<void>
}

// ======
// STYLES
// ======
const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.ui.blueDark,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    background: theme.colors.ui.white,
    padding: '3rem',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2rem',
  },
  logo: { height: '70px' },
  headerContainer: { textAlign: 'center' },
  header: { fontSize: '1.5rem', fontWeight: 300 },
  inputContainer: { display: 'flex', flexDirection: 'column', gap: '1rem' },
  input: { display: 'flex', flexDirection: 'column', gap: '0.4rem' },
  textField: { width: '333px' },
  errorSpan: { fontSize: theme.fontSizes.medium, color: 'red' },
  subscribeNowBtn: {
    height: '52px',
    width: '335.58px',
    backgroundColor: theme.colors.ui.blueDark,
    color: theme.colors.ui.white,
    padding: '12px 90px',
    fontSize: '1rem',
    '& .MuiButton-label': { textTransform: 'none', fontWeight: 300 },
    '&:hover': { backgroundColor: theme.colors.ui.blueDark },
  },
})

// =========
// COMPONENT
// =========
const CodeEmailSubmitPage = ({
  urlCode,
  userEnteredCodeError,
  emailError,
  orgNameError,
  orgDomainError,
  loadingCustomerCouponInfo,
  email,
  handleSetEmail,
  handleSubmitCodeAndEmail,
}: CodeEmailSubmitPageProps) => {
  const classes = useStyles()

  const [userEnteredCode, setUserEnteredCode] = useState('')
  const [orgName, setOrgName] = useState('')
  const [orgDomain, setOrgDomain] = useState('')

  useEffect(() => {
    async function handleEnterKeyPress(event) {
      if (event.key === 'Enter') {
        await handleSubmitCodeAndEmail(userEnteredCode, email, orgName, orgDomain)
      }
    }

    document.addEventListener('keydown', handleEnterKeyPress)

    return () => {
      document.removeEventListener('keydown', handleEnterKeyPress)
    }
  }, [userEnteredCode, email, orgName, orgDomain])

  return (
    <Box className={classes.container}>
      <Box className={classes.contentContainer}>
        <img src={OrpheusLogoWithBlueText} className={classes.logo} />

        <Box className={classes.headerContainer}>
          <Typography className={classes.header}>Welcome to Orpheus Portal</Typography>
          {urlCode ? (
            <Typography>{`Your code is: ${urlCode}`}</Typography>
          ) : (
            <Typography>Please enter your referral code</Typography>
          )}
        </Box>

        <Box className={classes.inputContainer}>
          {!urlCode ? (
            <Box className={classes.input}>
              <TextField
                label="Code"
                variant="outlined"
                onChange={(event) => setUserEnteredCode(event.target.value)}
                className={classes.textField}
              />
              {userEnteredCodeError ? <span className={classes.errorSpan}>{userEnteredCodeError}</span> : null}
            </Box>
          ) : null}

          <Box className={classes.input}>
            <TextField
              label="Email"
              variant="outlined"
              onChange={(event) => handleSetEmail(event.target.value)}
              className={classes.textField}
            />
            {emailError ? <span className={classes.errorSpan}>{emailError}</span> : null}
          </Box>

          <Box className={classes.input}>
            <TextField
              label="Organisation Name"
              variant="outlined"
              onChange={(event) => setOrgName(event.target.value)}
              className={classes.textField}
            />
            {orgNameError ? <span className={classes.errorSpan}>{orgNameError}</span> : null}
          </Box>

          <Box className={classes.input}>
            <TextField
              label="Organisation Domain"
              variant="outlined"
              onChange={(event) => setOrgDomain(event.target.value)}
              className={classes.textField}
            />
            {orgDomainError ? <span className={classes.errorSpan}>{orgDomainError}</span> : null}
          </Box>
        </Box>

        <Button
          className={classes.subscribeNowBtn}
          onClick={() => handleSubmitCodeAndEmail(userEnteredCode, email, orgName, orgDomain)}
        >
          {loadingCustomerCouponInfo ? <Loaders.Spinner size={25} /> : 'Redeem discount'}
        </Button>
      </Box>
    </Box>
  )
}

export default CodeEmailSubmitPage
