import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import snackbar from 'src/context/snackbar'
import axios from 'axios'
import { getJwtPayload } from 'src/auth/payload'
import { useDispatch, useSelector } from 'react-redux'
import { Loaders } from 'src/components'
import { gql as GQL } from 'apollo-boost'
import {
  recordUserInteraction,
  selfUpgradeModalAttemptedConfirmation,
  selfUpgradeModalSucceededConfirmation as selfUpgradeModalSucceededConfirmation,
} from 'src/services/posthog'
import client from 'src/network/apollo-client'
import Cookies from 'js-cookie'
import { persistor } from 'src/redux/store'
import { getFirstActiveTab } from 'src/routers/router-helpers'

interface ConfirmationModalProps {
  isOpen: boolean
  onClose: () => void
  //   onConfirm: () => void
  title: string
}

// =======
// QUERIES
// =======
const UPGRADE_USER = GQL`
  mutation upgradeUser
   {
    upgradeUser
  }
`

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isOpen, onClose, title, content }) => {
  const { userId, organisationId } = getJwtPayload()
  const dispatch = useDispatch()
  const { selectedSubscriptionPackage } = useSelector((state) => state.ui)

  const { enqueueSnackbar } = snackbar.Consumer()
  const [isLoading, setIsLoading] = useState(false)

  const handleUpgradeConfirmation = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    try {
      const upgradeSubscription = async () => {
        return axios.post('/payment-portal-lite/upgrade', {
          userId,
          productId: selectedSubscriptionPackage?.productId,
          organisationId,
        })
      }

      await upgradeSubscription().then(() => {
        recordUserInteraction(selfUpgradeModalSucceededConfirmation)
        dispatch({ type: 'SUBSCRIPTION_PACKAGES_INFO', payload: {} })
        dispatch({ type: 'SELECTED_SUBSCRIPTION_PACKAGE', payload: {} })
        client
          .mutate({
            mutation: UPGRADE_USER,
          })
          .then((response) => {
            recordUserInteraction(selfUpgradeModalSucceededConfirmation)
            const { token, expires, thirdPartyCompanyName, organisationName } = JSON.parse(response.data.upgradeUser)
            Cookies.set('auth-token', token, {
              expires: expires,
            })
            dispatch({
              type: 'LOGIN',
              payload: { organisationName, thirdPartyCompanyName },
            })
            dispatch({
              type: 'USER_JUST_LOGGED_IN',
              payload: { hasUserJustLoggedIn: true },
            })
            persistor.flush().then(() => {
              const firstActiveTab = getFirstActiveTab()
              window.location.pathname = firstActiveTab
            })
          })
        enqueueSnackbar(`You subscription has been successfully upgraded.`, {
          variant: 'success',
        })
      })
    } catch (error) {
      console.log(':: error', error)
      recordUserInteraction(selfUpgradeModalAttemptedConfirmation)
      enqueueSnackbar('Something went wrong, please try again later', {
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{`Are you sure you want to upgrade to the ${selectedSubscriptionPackage?.description} plan?`}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpgradeConfirmation} color="primary" autoFocus>
          {isLoading ? <Loaders.Spinner size={18} /> : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationModal
