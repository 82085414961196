import React, { Dispatch, SetStateAction, LegacyRef } from 'react'
import { Tag, TextInput } from 'components'
import { theme } from 'src/styling'
import { makeStyles, Box } from '@material-ui/core'
import { recordUserInteraction, userRemovedSearchTermFromFilter } from 'src/services/posthog'
import { ReactComponent as SearchExploreIcon } from 'src/images/icons/search-explore.svg'

// ======
// TYPES
// ======
interface FilterInputValuesInterface {
  id?: number
  title: string
  isKeyword?: boolean
}
type MultiItemTextInputInterface = {
  currentInput?: string
  onInputChange?: (val: string) => void
  filterInputValues?: FilterInputValuesInterface[]
  onFilterInputValuesChange: Dispatch<SetStateAction<(string | number | object | { id: number; title: string })[]>>
  onKeywordInputChange?: (isSearchAllowed: boolean) => void
  onSubmit: () => void
  onCursor?: Dispatch<SetStateAction<number>>
  cursor?: number
  onDropDownOpen: (dropDownState: boolean) => void
  dropDownListSize?: number
  dropDownListElementRef?: React.MutableRefObject<HTMLDivElement[]>
  dropDownOpen?: boolean
  onDropDownSelection?: () => void
  inputPlaceholder?: string
  inputType?: 'email' | 'text'
  isUnique?: boolean
  caseSensitive?: boolean
  textInputWidth?: string | number
  textInputRef?: LegacyRef<HTMLDivElement>
  createSearchHistory?: () => void
  showSearchIcon?: boolean
}

// ======
// STYLES
// ======
const useStyles = makeStyles({
  multiItemTextInputContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'auto',
    height: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.ui.white,
    border: `0.9px solid ${theme.colors.ui.greyLight}`,
    marginRight: 15,
    paddingLeft: 6,
    '&:hover': {
      border: `0.9px solid ${theme.colors.ui.greyDark}`,
    },
  },
  inputContainer: {
    display: 'flex',
    flex: ' 1 1 4.5rem',
    marginLeft: '-0.225rem',
  },
  errorMessage: {
    position: 'relative',
    top: ' 0.45rem',
    color: theme.colors.ui.red,
  },
  searchIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '5px',
  },
})
// =========
// COMPONENT
// =========
const GlobalMultiItemTextInput = React.memo(function GlobalMultiItemTextInputComponent(
  props: MultiItemTextInputInterface
) {
  const classes = useStyles()
  //If value has an id, compare by id. Otherwise compare by value
  const handleDeleteValue = (value: { id?: number; title: string }): void => {
    props.onFilterInputValuesChange(
      props.filterInputValues.filter((currentValue: { id?: number; title: string }) =>
        currentValue?.id ? currentValue.id !== value.id : currentValue.title !== value.title
      )
    )
  }

  return (
    <>
      <div
        id={'filter-input-box'}
        ref={props.textInputRef}
        className={classes.multiItemTextInputContainer}
        onClick={() => props.onDropDownOpen(true)}
      >
        {props.showSearchIcon && (
          <div className={classes.searchIconContainer}>
            <SearchExploreIcon />
          </div>
        )}

        {props.filterInputValues.map((value, index) => (
          <Tag
            key={index}
            value={value?.isKeyword ? `${value.title} (keyword)` : value.title}
            isDeletable={true}
            onDelete={(): void => {
              recordUserInteraction(userRemovedSearchTermFromFilter)
              handleDeleteValue(value)
            }}
          />
        ))}
        <Box className={classes.inputContainer}>
          <TextInput
            // input entered by the user
            currentInput={props.currentInput}
            // function to handle input change
            onInputChange={props.onInputChange}
            // array of keywords added to the search
            filterInputValues={props.filterInputValues}
            // function to add the keyword typed by the user to the array of filterInputValues
            onKeywordInputChange={props.onKeywordInputChange}
            // satete function to update the inputFilterValues array
            onFilterInputValuesChange={props.onFilterInputValuesChange}
            autocomplete="off"
            placeholder={props.filterInputValues.length === 0 ? props.inputPlaceholder : null}
            type={props.inputType}
            onSubmit={props.onSubmit}
            //cursor of the drop down list, it is the currently highlighted list item in the drop down
            onCursor={props.onCursor}
            cursor={props.cursor}
            // drop down
            dropDownListSize={props.dropDownListSize}
            dropDownListElementRef={props.dropDownListElementRef}
            dropDownOpen={props.dropDownOpen}
            onDropDownSelection={props.onDropDownSelection}
            onDropDownOpen={props.onDropDownOpen}
            createSearchHistory={props.createSearchHistory}
          />
        </Box>
      </div>
    </>
  )
})

export { GlobalMultiItemTextInput }
