import React, { ReactElement, useState } from 'react'
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { useHistory } from 'react-router-dom'
import { ReactComponent as AlertNew } from 'src/images/icons/alert-new.svg'
import { ReactComponent as Tick } from 'src/images/icons/tick.svg'
import { ReactComponent as Arrow } from 'src/images/icons/arrow.svg'
import TabHeader from './tab-header'
import Grid from '@material-ui/core/Grid'
import { ColumnChart, LineChart } from 'components'
import { theme as globalTheme } from 'src/styling'
import moment from 'moment'
import { commonStyles } from './styles'
import { applyFiltersToUrl, getDateRangeDefaultParam } from 'src/utils/filters'
import {
  recordUserInteraction,
  userClickedGoToIntelligenceReports,
  userClickedGoToDeepDarkWebMentions,
} from 'src/services/posthog'
import { Tooltip } from '@material-ui/core'
import { ReactComponent as ToolTipIcon } from 'src/images/icons/tool-tip.svg'
import constants from 'src/constants'
import WarningIcon from '@material-ui/icons/Warning'
import { getJwtPayload } from 'src/auth/payload'

// ===================
// PROPS
// ===================

type BoxItemProps = {
  leftChild?: ReactElement
  leftChildPadding?: string
  middleChild: ReactElement
  middleChildPadding?: string
  rightChild?: ReactElement
  rightChildPadding?: string
  rightChildClassName?: string
}

type StyleProps = { exploitationStatusColor?: string }

// ===================
// STYLES
// ===================
const useStyles = makeStyles((theme: Theme) => ({
  //======Body
  bodyContainer: {
    width: '98%',
    paddingLeft: '1%',
  },
  arrowContainer: {
    backgroundColor: globalTheme.colors.ui.whiteSmoke,
    maxWidth: '40.5px',
    maxHeight: '36px',
    minWidth: '40.5px',
    minHeight: '36px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: globalTheme.colors.ui.snow,
    },
  },
  boxContainer: {
    padding: '9px 20px 9px 20px',
    border: `0.9px solid ${globalTheme.colors.ui.greyLight}`,
    marginBottom: '9px',
    wordWrap: 'break-word',
    fontSize: globalTheme.fontSizes.mediumLarge,
    '& span': {
      color: globalTheme.colors.ui.blueDark,
    },
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: globalTheme.fontSizes.medium,
      textAlign: 'center',
    },
  },
  summary: {
    fontSize: globalTheme.fontSizes.mediumLarge,
    lineHeight: '1.35',
    marginBottom: '0.9rem',
  },
  smallDataContainer: {
    display: 'flex',
    padding: '22px 22px',
    flexGrow: 1,
    border: '1px solid #e1e1e1',
    marginBottom: '10px',
    [theme.breakpoints.between('xs', 'md')]: {
      textAlign: 'center',
    },
  },
  titleOnly: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& p': { marginBottom: 0 },
    fontSize: globalTheme.fontSizes.mediumLarge,
    margin: '0',
    lineHeight: '1.3rem',
    padding: '8px',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  titleOnlyExploited: (props: StyleProps) => ({ backgroundColor: props.exploitationStatusColor }),
  titleOnlyNotExploited: {
    backgroundColor: '#E64544',
    justifyContent: 'normal',
    gap: '0.6rem',
  },
  titleAnswerYes: {
    fontSize: globalTheme.fontSizes.mediumLarge,
    margin: '0',
    lineHeight: '1.3rem',
    backgroundColor: '#E64544',
    padding: '9.6px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: globalTheme.colors.ui.white,
  },
  titleAnswerPercentage: {
    fontSize: globalTheme.fontSizes.mediumLarge,
    margin: '0',
    lineHeight: '1.3rem',
    backgroundColor: '#E64544',
    padding: '8px',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  probabilityExploitation: {
    fontSize: globalTheme.fontSizes.mediumLarge,
    margin: '0',
    lineHeight: '1.3rem',
    backgroundColor: '#E64544',
    padding: '8px',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  cveInWildtitleOnly: {
    fontSize: globalTheme.fontSizes.mediumLarge,
    margin: '0',
    lineHeight: '1.3rem',
    backgroundColor: '#E1E1E1',
    padding: '8px',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'left',
    [theme.breakpoints.between('xs', 'md')]: {
      textAlign: 'center',
    },
  },
  iconBox: {
    [theme.breakpoints.between('xs', 'md')]: {
      display: 'flex',
      justifyContent: ' center',
    },
  },
  icon: { ...globalTheme.customStyles.icon },
  warningIcon: {
    marginTop: '5px',
    height: '33px',
    width: '32px',
    color: (props: StyleProps) => props.exploitationStatusColor,
  },
  exploitationTooltipContainer: { cursor: 'pointer', marginRight: '1rem' },
  exploitationTooltipIconContainer: { height: '0.9rem', width: '0.9rem', '& svg': { color: 'white' } },
  ovssBreakdownContainer: { flexDirection: 'column', textAlign: 'left', fontSize: globalTheme.fontSizes.mediumLarge },
  ovssBreakdownHeader: { fontWeight: 'bold', lineHeight: '1.2rem', textAlign: 'center', marginTop: '5px' },
  ovssBreakdownContentContainer: { display: 'flex', width: '100%', gap: '.4rem' },
  ovssBreakdownContentLeft: { width: '55%', padding: '.5rem', marginLeft: '1rem' },
  ovssBreakdownContentRight: { width: '45%', padding: '.5rem', marginLeft: '1rem' },
  ovssBreakdownSubHeader: {
    marginBottom: '0.6rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.4rem',
    '& span': { fontWeight: 'bold', color: globalTheme.colors.ui.blue },
  },
  extraTitlePadding: { marginTop: '5px' },
}))

const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: globalTheme.colors.ui.blueDark,
    color: 'white',
    maxWidth: 360,
    boxShadow: '0px 0px 22.5px -3.6px rgba(0,0,0,0.5)',
    fontSize: 13.5,
    padding: 14.4,
    lineHeight: 1.44,
  },
  arrow: {
    color: globalTheme.colors.ui.blueDark,
    boxShadow: '0px 0px 22.5px -3.6px rgba(0,0,0,0.5)',
    marginBottom: -0.9,
  },
}))(Tooltip)

// ===================
// FUNCTIONS
// ===================

const threatActorCheck = (data: any): number => (data?.profiles?.totalCount ? data.profiles.totalCount : 0)

const intrepCheck = (data: any): number => (data?.intreps?.totalCount ? data.intreps.totalCount : 0)

const deepDarkWebCheck = (data: any): number => (data?.totalCount ? data?.totalCount : 0)

const getExploitationStatusColorAndText = (exploitationStatus: string) => {
  let exploitationStatusColorAndText = { color: '#E1E1E1', text: constants.NOT_EXPLOITED_EXPLOITATION_TOOLTIP_TEXT }
  if (exploitationStatus === 'CONFIRMED')
    exploitationStatusColorAndText = {
      color: globalTheme.colors.ui.goldLight,
      text: constants.CONFIRMED_EXPLOITATION_TOOLTIP_TEXT,
    }
  if (exploitationStatus === 'WIDESPREAD')
    exploitationStatusColorAndText = { color: '#E64544', text: constants.WIDESPREAD_EXPLOITATION_TOOLTIP_TEXT }
  return exploitationStatusColorAndText
}

// ===================
// FUNCTIONS THAT RETURNS TSX
// ===================

const BoxItem = ({
  leftChild,
  leftChildPadding,
  middleChild,
  middleChildPadding,
  rightChild,
  rightChildPadding,
  rightChildClassName,
}: BoxItemProps) => {
  const classes = useStyles({})

  const noChildDisplayed = !leftChild && !rightChild
  const oneChildDisplayed = (!leftChild && !!rightChild) || (!!leftChild && !rightChild)
  const middleChildGridLength = noChildDisplayed ? 12 : oneChildDisplayed ? 11 : 10

  return (
    <Box className={classes.smallDataContainer}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        {leftChild && (
          <Grid item xs={12} md lg>
            <Box className={classes.iconBox} padding={leftChildPadding}>
              {leftChild}
            </Box>
          </Grid>
        )}
        <Grid item xs={12} md={middleChildGridLength} lg={middleChildGridLength}>
          <Box padding={middleChildPadding}>{middleChild}</Box>
        </Grid>
        {rightChild && (
          <Grid item xs={12} md lg>
            <Box padding={rightChildPadding} className={rightChildClassName}>
              {rightChild}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

const exploitationCheck = (data: any) => {
  const exploitationStatus = data?.exploitationStatus
  const exploitationStatusColorAndText = getExploitationStatusColorAndText(exploitationStatus)

  const classes = useStyles({ exploitationStatusColor: exploitationStatusColorAndText.color })

  const [exploitedStatusTooltipOpen, setExploitedStatusTooltipOpen] = useState(false)
  const [futureExploitationTooltipOpen, setFutureExploitationStatusTooltipOpen] = useState(false)

  return (
    <>
      <BoxItem
        leftChild={
          exploitationStatus !== 'NOT EXPLOITED' ? (
            <WarningIcon className={`${classes.icon} ${classes.warningIcon}`} />
          ) : null
        }
        middleChild={
          <div className={`${classes.titleOnly} ${classes.titleOnlyExploited}`}>
            <p>Exploitation Status: {`${exploitationStatus}`}</p>
            <div
              className={classes.exploitationTooltipContainer}
              onMouseEnter={() => setExploitedStatusTooltipOpen(true)}
              onMouseLeave={() => setExploitedStatusTooltipOpen(false)}
            >
              <CustomTooltip
                title={exploitationStatusColorAndText.text}
                open={exploitedStatusTooltipOpen}
                arrow
                interactive
                placement="right"
              >
                <Box className={classes.exploitationTooltipIconContainer}>
                  <ToolTipIcon />
                </Box>
              </CustomTooltip>
            </div>
          </div>
        }
      />
      {exploitationStatus === 'NOT EXPLOITED' && (
        <BoxItem
          leftChild={<AlertNew className={classes.icon} />}
          middleChild={
            <div className={`${classes.titleOnly} ${classes.titleOnlyNotExploited}`}>
              <p>CVE likelihood of future exploitation</p>
              <div
                className={classes.exploitationTooltipContainer}
                onMouseEnter={() => setFutureExploitationStatusTooltipOpen(true)}
                onMouseLeave={() => setFutureExploitationStatusTooltipOpen(false)}
              >
                <CustomTooltip
                  title={`Our probability of exploit model considers a wide range of data sources and produces a 
                    probability that this CVE will gain either widespread or confirmed exploitation status`}
                  open={futureExploitationTooltipOpen}
                  arrow
                  interactive
                  placement="right"
                >
                  <Box className={classes.exploitationTooltipIconContainer}>
                    <ToolTipIcon />
                  </Box>
                </CustomTooltip>
              </div>
            </div>
          }
          rightChild={
            <p className={classes.titleAnswerYes}>
              {data?.cveLifecyclePrediction?.probability ? `${data.cveLifecyclePrediction.probability} %` : 'N/A'}
            </p>
          }
        />
      )}
    </>
  )
}

// ===================
// MAIN COMPONENT
// ===================
const TabSummary = ({
  onClose,
  cveData,
  ovssData,
  ovssScoresOverTime,
  twitterPostsAggregations,
  explore,
  cveId,
  tabName,
  scoreTitleName,
  organisationCompaniesByCve,
}) => {
  const { organisationTabs, viewingAsThirdParty } = getJwtPayload()
  const hasThirdPartySubscription = !!organisationTabs['/third-party-risk']
  const classes = useStyles({})
  const commonClasses = commonStyles()
  const dateRange = getDateRangeDefaultParam()

  const history = useHistory()

  const ovssScoresOverTimeChartData = ovssScoresOverTime?.map((ovssScore) => {
    return { x: new Date(ovssScore.tblTimestamp).getTime(), y: ovssScore.ovss }
  })

  const columnChartTwitterPostsSeries = {
    name: 'Social Media Posts',
    color: globalTheme.colors.ui.blueDark,
    data: twitterPostsAggregations?.map((twitterPostAggregation: any) => {
      return { x: new Date(twitterPostAggregation.date).getTime(), y: twitterPostAggregation.totalPosts }
    }),
  }

  const handleExploreDeepDarkWeb = (cve: string) => {
    recordUserInteraction(userClickedGoToDeepDarkWebMentions)
    const now = moment()
    const to = now.format('YYYY-MM-DD')
    const from = now.subtract(12, 'months').format('YYYY-MM-DD')
    window.location.href = `/explore?tag=${cve}&from=${from}&to=${to}&source=forums&source=comms_channels&source=marketplaces&order=date,DESC`
  }

  const handleClickThreatActor = (data) => {
    const tags = data?.profiles?.edges.map(({ node }) => ({ id: node.id, title: node.title }))
    const tagParams = applyFiltersToUrl({ tags })
    history.push(`/profiles?${tagParams}`)
  }

  return (
    <Box>
      <TabHeader
        title={cveId}
        cveData={cveData}
        ovssData={ovssData}
        scoreTitleName={scoreTitleName}
        tabName={tabName}
      />
      <Box className={classes.bodyContainer}>
        <Box className={classes.boxContainer}>
          <p className={`${commonClasses.titlewithSubtitle} ${classes.extraTitlePadding}`}>Summary</p>
          <Box paddingTop={1.8}>
            <p className={classes.summary}>{cveData?.description}</p>
          </Box>
        </Box>
        {exploitationCheck(cveData)}
        <Box className={`${classes.boxContainer} ${classes.ovssBreakdownContainer}`}>
          <p className={classes.ovssBreakdownHeader}>OVSS Breakdown</p>
          <div className={classes.ovssBreakdownContentContainer}>
            <ul className={classes.ovssBreakdownContentLeft}>
              <li>
                <p className={classes.ovssBreakdownSubHeader}>
                  Open Source Exploit Code Available: <span>{`${ovssData.exploitCodeAvailable}`}</span>
                </p>
              </li>
              <li>
                <p className={classes.ovssBreakdownSubHeader}>
                  Open Source Scanning Template Available: <span>{`${ovssData.nuclei ? 'TRUE' : 'FALSE'}`}</span>
                </p>
              </li>
              <li>
                <p className={classes.ovssBreakdownSubHeader}>
                  Cyber Security Researchers Claim Exploitation:{' '}
                  <span>{`${ovssData.attackerkb ? 'TRUE' : 'FALSE'}`}</span>
                </p>
              </li>
            </ul>
            <ul className={classes.ovssBreakdownContentRight}>
              <li>
                <p className={classes.ovssBreakdownSubHeader}>
                  Honeypot Malicious Activity: <span>{`${ovssData.honeypotMaliciousActivity}`}</span>
                </p>
              </li>
              <li>
                <p className={classes.ovssBreakdownSubHeader}>
                  Malware Samples: <span>{`${ovssData.malwareSamples}`}</span>
                </p>
              </li>
              <li>
                <p className={classes.ovssBreakdownSubHeader}>
                  Antivirus Engines: <span>{`${ovssData.antivirusEngines}`}</span>
                </p>
              </li>
            </ul>
          </div>
        </Box>

        {twitterPostsAggregations?.length ? (
          <Box className={classes.boxContainer}>
            <p className={`${commonClasses.titlewithSubtitle} ${classes.extraTitlePadding}`}>Chatter</p>
            <Box paddingTop={1.8}>
              <ColumnChart
                height={270}
                series={[columnChartTwitterPostsSeries]}
                showPointFormatCategory
                pointCategoryNeedsDateFormat
              />
            </Box>
          </Box>
        ) : null}

        <Box className={classes.boxContainer}>
          <h3 className={`${commonClasses.titlewithSubtitle} ${classes.extraTitlePadding}`}>OVSS Over Time</h3>
          <Box paddingTop={1.8}>
            <LineChart
              lineMarker
              showXLabels
              names={['OVSS Score']}
              valuePrefix={'Score: '}
              maxYAxis={100}
              data={[ovssScoresOverTimeChartData]}
              height={270}
            />
          </Box>
        </Box>

        <BoxItem
          leftChild={!intrepCheck(cveData) ? <Tick className={classes.icon} /> : <AlertNew className={classes.icon} />}
          middleChild={
            <p className={commonClasses.titlewithSubtitle}>
              Orpheus Intelligence Reports
              <br />
              <span>
                {intrepCheck(cveData) === 1 ? `${intrepCheck(cveData)} Report` : `${intrepCheck(cveData)} Reports`}
              </span>
            </p>
          }
          rightChildClassName={commonClasses.horizontalCenter}
          rightChild={
            <Button
              disabled={!intrepCheck(cveData)}
              style={!intrepCheck(cveData) ? { opacity: '0.5' } : {}}
              className={classes.arrowContainer}
              onClick={() => {
                recordUserInteraction(userClickedGoToIntelligenceReports)
                window.location.href = `/intelligence?${dateRange}&tag=${cveData?.tag?.id},${cveId}`
              }}
              id="cve-modal-ireports-btn"
            >
              <Arrow className={classes.icon} />
            </Button>
          }
        />
        <BoxItem
          leftChild={
            !threatActorCheck(cveData) ? <Tick className={classes.icon} /> : <AlertNew className={classes.icon} />
          }
          middleChild={
            <p className={commonClasses.titlewithSubtitle}>
              Threat actors currently exploiting this CVE
              <br />
              <span>
                {threatActorCheck(cveData) === 1
                  ? `${threatActorCheck(cveData)} Profile`
                  : `${threatActorCheck(cveData)} Profiles`}
              </span>
            </p>
          }
          rightChildClassName={commonClasses.horizontalCenter}
          rightChild={
            <Button
              disabled={!threatActorCheck(cveData)}
              style={!threatActorCheck(cveData) ? { opacity: '0.5' } : {}}
              className={classes.arrowContainer}
              onClick={() => handleClickThreatActor(cveData)}
              id="cve-modal-deep-web-btn"
            >
              <Arrow className={classes.icon} />
            </Button>
          }
        />

        <BoxItem
          leftChild={
            !deepDarkWebCheck(explore) ? <Tick className={classes.icon} /> : <AlertNew className={classes.icon} />
          }
          middleChild={
            <p className={commonClasses.titlewithSubtitle}>
              Deep & Dark Web Mentions (past 12 months)
              <br />
              <span>
                {threatActorCheck(cveData) === 1
                  ? `${deepDarkWebCheck(explore)} Mention`
                  : `${deepDarkWebCheck(explore)} Mentions`}
              </span>
            </p>
          }
          rightChildClassName={commonClasses.horizontalCenter}
          rightChild={
            <Button
              disabled={!deepDarkWebCheck(explore)}
              style={!deepDarkWebCheck(explore) ? { opacity: '0.5' } : {}}
              className={classes.arrowContainer}
              onClick={() => handleExploreDeepDarkWeb(cveId)}
              id="cve-modal-deep-web-btn"
            >
              <Arrow className={classes.icon} />
            </Button>
          }
        />
        {hasThirdPartySubscription && !viewingAsThirdParty ? (
          <BoxItem
            leftChild={
              !organisationCompaniesByCve?.totalCount ? (
                <Tick className={classes.icon} />
              ) : (
                <AlertNew className={classes.icon} />
              )
            }
            middleChild={
              <p className={commonClasses.titlewithSubtitle}>
                Your third party companies affected by this CVE
                <br />
                <span>
                  {organisationCompaniesByCve?.totalCount === 1
                    ? `1 Company`
                    : `${organisationCompaniesByCve?.totalCount} Companies`}
                </span>
              </p>
            }
            rightChildClassName={commonClasses.horizontalCenter}
            rightChild={
              <Button
                disabled={!organisationCompaniesByCve?.totalCount}
                style={!organisationCompaniesByCve?.totalCount ? { opacity: '0.5' } : {}}
                className={classes.arrowContainer}
                onClick={() => {
                  history.push(`/third-party-risk?cve=${organisationCompaniesByCve?.companyCveId},${cveId},cve`)
                }}
              >
                <Arrow className={classes.icon} />
              </Button>
            }
          />
        ) : null}
      </Box>
    </Box>
  )
}

export default TabSummary
