import moment from 'moment'

// Actions type
type CloseNavbarAction = {
  type: 'CLOSE_NAVBAR'
}
type OpenNavbarAction = {
  type: 'OPEN_NAVBAR'
}

type RbvmPageUploadAction = {
  type: 'SET_RBVM_PAGE_UPLOAD'
}

type RbvmDownloadAction = {
  type: 'SET_RBVM_DOWNLOAD'
  payload: {
    rbvmDownload: boolean
  }
}

type RbvmIntegrationAction = {
  type: 'SET_RBVM_INTEGRATION_ERROR'
  payload: {
    rbvmIntegrationError: string | null
  }
}

type RbvmUploadAction = {
  type: 'SET_RBVM_UPLOAD'
  payload: {
    isRbvmUploadProcessing: boolean
    rbvmUploadError: any
  }
}

type CompanyUploadAction = {
  type: 'SET_COMPANY_UPLOAD'
  payload: {
    companyDownloading: any[]
  }
}

type VulnerabilityUploadAction = {
  type: 'SET_VULNERABILITY_UPLOAD'
  payload: {
    vulnerabilityDownloading: any[]
  }
}

type AddFileLoadingAction = {
  type: 'ADD_FILE_DOWNLOADING'
  payload: {
    file: object[]
  }
}
type ResetFileLoadingAction = {
  type: 'RESET_FILES_DOWNLOADING'
}

type RemoveFileLoadingAction = {
  type: 'REMOVE_FILES_DOWNLOADING'
  payload: {
    files: any
  }
}

type NotificationAction = {
  type: 'NOTIFICATION_NAVBAR_COUNT'
  payload: {
    notificationCount: number
  }
}

type NotificationChangeAction = {
  type: 'NOTIFICATION_NAVBAR_CHANGE'
  payload: {
    notificationChange: string
  }
}

type AttackSurfaceTableRefreshAction = {
  type: 'SET_ATTACK_SURFACE_TABLE_REFRESH'
  payload: {
    attackSurfaceTableRefresh: boolean
    scoreGaugeNeedsRefresh: boolean
  }
}

type DrpTableRefreshAction = {
  type: 'SET_DRP_TABLE_REFRESH'
  payload: {
    drpTableRefresh: boolean
  }
}

type AttackSurfaceMapViewTableRefreshAction = {
  type: 'SET_ATTACK_SURFACE_MAP_VIEW_TABLE_REFRESH'
  payload: {
    attackSurfaceMapViewTableRefresh: boolean
  }
}

type IsAcknowledgeAction = {
  type: 'SET_IS_ACKNOWLEDGE_TABLE'
  payload: {
    isAcknowledgedTable: boolean
  }
}

type UpdateThirdPartyCompanyNameAction = {
  type: 'UPDATE_THIRD_PARTY_COMPANY_NAME'
  payload: {
    thirdPartyCompanyName: string
  }
}
type UpdateShowThirdPartyCompanyChangedAction = {
  type: 'SHOW_THIRD_PARTY_COMPANY_CHANGED'
  payload: {
    showThirdPartyCompanyChange: boolean
  }
}

type UpdateThirdPartyUserCreatedGroupAction = {
  type: 'SHOW_THIRD_PARTY_COMPANY_GROUP_CHANGED'
  payload: {
    showThirdPartyCompanyGroupChange: boolean
  }
}

type AccountDeactivatedAction = {
  type: 'ACCOUNT_DEACTIVATED'
  payload: {
    isAccountDeactivated: boolean
  }
}

type SubscriptionPackageBasePayload = {
  description: string
  productId: string
  monthlyPriceId: string
  quarterlyPriceId: string
  yearlyPriceId: string
  monthlySubscriptionPrice: number
  quarterlySubscriptionPrice: number
  yearlySubscriptionPrice: number
}
type SubscriptionPackagesInfo = {
  type: 'SUBSCRIPTION_PACKAGES_INFO'
  payload: {
    promoCode: string
    customerId: string
    couponId: string
    partnerBrandingLogoUrl: string
    couponPercentOff: number
    organisationName: string
    organisationDomain: string
    email: string
    products: SubscriptionPackageBasePayload[]
  }
}

type SelectedSubscriptionPackage = {
  type: 'SELECTED_SUBSCRIPTION_PACKAGE'
  payload: {
    selectedSubscriptionPackage: SubscriptionPackageBasePayload
  }
}

type JustLoggedInStatusAction = {
  type: 'JUST_LOGGED_IN_STATUS'
  payload: {
    justLoggedInStatus: boolean
  }
}
export type UIReducerActions =
  | CloseNavbarAction
  | OpenNavbarAction
  | NotificationAction
  | NotificationChangeAction
  | RbvmPageUploadAction
  | RbvmDownloadAction
  | RbvmIntegrationAction
  | AddFileLoadingAction
  | RemoveFileLoadingAction
  | ResetFileLoadingAction
  | AttackSurfaceTableRefreshAction
  | DrpTableRefreshAction
  | AttackSurfaceMapViewTableRefreshAction
  | IsAcknowledgeAction
  | UpdateThirdPartyCompanyNameAction
  | UpdateShowThirdPartyCompanyChangedAction
  | UpdateThirdPartyUserCreatedGroupAction
  | RbvmUploadAction
  | CompanyUploadAction
  | VulnerabilityUploadAction
  | AccountDeactivatedAction
  | SubscriptionPackagesInfo
  | SelectedSubscriptionPackage
  | JustLoggedInStatusAction

// State type
export type UIReducerState = {
  isNavbarOpen: boolean
  notificationCount: number
  notificationChange: string
  rbvmPageUpload: number
  filesDownloading: any
  attackSurfaceTableRefresh: boolean
  drpTableRefresh: boolean
  attackSurfaceMapViewTableRefresh: boolean
  isAcknowledgedTable: boolean
  scoreGaugeNeedsRefresh: boolean
  thirdPartyCompanyName: string
  showThirdPartyCompanyChange: boolean
  showThirdPartyCompanyGroupChange: boolean
  rbvmDownload: boolean
  isRbvmUploadProcessing: boolean
  rbvmUploadError: any
  companyDownloading: any[]
  vulnerabilityDownloading: any[]
  rbvmIntegrationError: string | null
  isAccountDeactivated: boolean | null
  subscriptionPackagesInfo: {}
  selectedSubscriptionPackage: {}
  justLoggedInStatus: boolean
}

// Reducer function type
type UIReducer = {
  (state: UIReducerState, action: UIReducerActions): UIReducerState
}

const initialState = {
  isNavbarOpen: true,
  notificationCount: 0,
  notificationChange: null,
  rbvmPageUpload: moment().unix(),
  filesDownloading: [],
  attackSurfaceTableRefresh: false,
  drpTableRefresh: false,
  attackSurfaceMapViewTableRefresh: false,
  scoreGaugeNeedsRefresh: false,
  isAcknowledgedTable: false,
  thirdPartyCompanyName: '',
  showThirdPartyCompanyChange: false,
  showThirdPartyCompanyGroupChange: false,
  companyDownload: false,
  companyDownloading: [],
  vulnerabilityDownloading: [],
  rbvmDownload: false,
  isRbvmUploadProcessing: false,
  rbvmUploadError: null,
  rbvmIntegrationError: null,
  isAccountDeactivated: false,
  subscriptionPackagesInfo: {},
  selectedSubscriptionPackage: {},
  justLoggedInStatus: false,
}

const uiReducer: UIReducer = (state = initialState, action) => {
  const newState = { ...state }

  switch (action.type) {
    case 'SET_RBVM_PAGE_UPLOAD':
      newState.rbvmPageUpload = moment().unix()
      return newState
    case 'SET_RBVM_INTEGRATION_ERROR':
      newState.rbvmIntegrationError = action.payload.rbvmIntegrationError
      return newState
    case 'SET_RBVM_UPLOAD':
      newState.isRbvmUploadProcessing = action.payload.isRbvmUploadProcessing
      newState.rbvmUploadError = action.payload.rbvmUploadError
      return newState

    case 'SET_RBVM_DOWNLOAD':
      newState.rbvmDownload = action.payload.rbvmDownload
      return newState
    case 'ADD_FILE_DOWNLOADING':
      const fileLoadingConcat = newState.filesDownloading.concat(action.payload.file)
      newState.filesDownloading = fileLoadingConcat
      return newState

    case 'REMOVE_FILES_DOWNLOADING':
      const filesStillDownloading2 = newState.filesDownloading.filter((fileLoading) => {
        return !action.payload.files.find(
          (file) =>
            file.companyScoreId === fileLoading.companyScoreId &&
            file.companyId === fileLoading.companyId &&
            file.fileType === fileLoading.type
        )
      })

      newState.filesDownloading = filesStillDownloading2
      return newState

    case 'RESET_FILES_DOWNLOADING':
      newState.filesDownloading = []
      return newState
    case 'CLOSE_NAVBAR':
      newState.isNavbarOpen = false

      return newState
    case 'OPEN_NAVBAR':
      newState.isNavbarOpen = true
      return newState
    case 'NOTIFICATION_NAVBAR_CHANGE':
      newState.notificationChange = action.payload.notificationChange

      return newState

    case 'NOTIFICATION_NAVBAR_COUNT':
      newState.notificationCount = action.payload.notificationCount

      return newState

    case 'SET_ATTACK_SURFACE_TABLE_REFRESH':
      newState.attackSurfaceTableRefresh = action.payload.attackSurfaceTableRefresh
      newState.scoreGaugeNeedsRefresh = action.payload.scoreGaugeNeedsRefresh
      return newState
    case 'SET_DRP_TABLE_REFRESH':
      newState.drpTableRefresh = action.payload.drpTableRefresh

      return newState
    case 'SET_ATTACK_SURFACE_MAP_VIEW_TABLE_REFRESH':
      newState.attackSurfaceMapViewTableRefresh = action.payload.attackSurfaceMapViewTableRefresh
      return newState
    case 'SET_IS_ACKNOWLEDGE_TABLE':
      newState.isAcknowledgedTable = action.payload.isAcknowledgedTable
      return newState
    case 'SHOW_THIRD_PARTY_COMPANY_CHANGED':
      newState.showThirdPartyCompanyChange = action.payload.showThirdPartyCompanyChange
      return newState
    case 'UPDATE_THIRD_PARTY_COMPANY_NAME':
      newState.thirdPartyCompanyName = action.payload.thirdPartyCompanyName
      return newState
    case 'SHOW_THIRD_PARTY_COMPANY_GROUP_CHANGED':
      newState.showThirdPartyCompanyGroupChange = action.payload.showThirdPartyCompanyGroupChange
      return newState
    case 'ACCOUNT_DEACTIVATED':
      newState.isAccountDeactivated = action.payload.isAccountDeactivated
      return newState
    case 'SUBSCRIPTION_PACKAGES_INFO': {
      newState.subscriptionPackagesInfo = {
        promoCode: action.payload?.promoCode,
        customerId: action.payload?.customerId,
        couponId: action.payload?.couponId,
        partnerBrandingLogoUrl: action.payload?.partnerBrandingLogoUrl,
        couponPercentOff: action.payload?.couponPercentOff,
        organisationName: action.payload?.organisationName,
        organisationDomain: action.payload?.organisationDomain,
        email: action.payload?.email,
        products: action.payload?.products,
      }
      return newState
    }
    case 'SELECTED_SUBSCRIPTION_PACKAGE': {
      newState.selectedSubscriptionPackage = action.payload.selectedSubscriptionPackage
      return newState
    }
    case 'JUST_LOGGED_IN_STATUS':
      newState.justLoggedInStatus = action.payload.justLoggedInStatus
      return newState
    default:
      return state
  }
}

export default uiReducer
