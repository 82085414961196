import React, { Fragment, useEffect, useState } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { theme } from 'src/styling'
import { useDispatch, useSelector } from 'react-redux'
import humanFormat from 'human-format'
import ReactTooltip from 'react-tooltip'
import { ReactComponent as ArrowRightIcon } from 'src/images/icons/arrow-right.svg'
import { ReactComponent as ArrowLeftIcon } from 'src/images/icons/arrow-left.svg'
import ThirdPartyIconWhite from 'src/images/navbar/third-party-icon.png'
import ThirdPartyIconActive from 'src/images/navbar/third-party-icon-active.png'
import { hot } from 'react-hot-loader/root'
import { Drawer, List, Link, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, Dialog } from '@material-ui/core'
import navbarLogoImage from 'src/images/orpheus/orpheus-logo-small.png'
import navbarLogoText from 'src/images/orpheus/orpheus-text.png'
import { useQuery } from '@apollo/react-hooks'
import { getRoutesFromPayload } from 'src/routers/router-helpers'
import {
  recordUserInteraction,
  userClickedOrpheusLogo,
  userClickedOnPageLink,
  userToggledNavBar,
  userClickedOnNavbarBottomIcons,
  userClickedOnUpgradeNavbar,
  selfUpgradeModalOpen,
  userViewingAsThirdPartyCompany,
} from 'src/services/posthog'
import { getJwtPayload } from 'src/auth/payload'
import SnackBar from '../../context/snackbar'
import Alerts, { UserAlertType } from '../alerts/alerts'
import SelectOrganisationModal from 'src/modals/select-organisation-modal'
import { getAlertDrawerOpenStateFromUrl, removeParamAndReturnUrl } from 'src/utils/filters'
import CveModal from '../../modals/cve-modal'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import GlobalSearchModal from 'src/modals/global-search-modal'
import clsx from 'clsx'
import { getTooltipText, mapTooltipPageImages, mapTooltipPageSummary } from './tooltip-generator'
import { navbarTopPages, navbarBottomPages } from './page-list'
import { gql as GQL } from 'apollo-boost'
import SelectCompany from '../select-company/select-company'
import UserProfile from './user-profile'
import { persistor } from 'src/redux/store'
import axios from 'axios'
import { Loaders } from '../loaders'
import SelfSignUpLandingPage from 'src/pages/self-service-signup/landing-page'

// ===================
// TYPES
// ===================

type StyleProps = {
  isUserOrganisation?: boolean
}

// ===================
// STYLES
// ===================

const useStyles = makeStyles<Theme, StyleProps>({
  root: {
    backgroundColor: theme.colors.ui.blueDark,
    zIndex: 1301,
  },

  drawer: {
    width: '9rem',
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: '9rem',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: '3.15rem',
    overflowX: 'hidden',
  },
  paper: {
    backgroundColor: theme.colors.ui.blueDark,
    color: theme.colors.ui.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&::-webkit-scrollbar': {
      width: '2px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.colors.ui.blueVeryLight,
      border: 'none',
      outline: 'none',
      '&:hover': {
        backgroundColor: theme.colors.ui.blueLight,
      },
    },
  },

  navbarLogoLink: {
    display: 'block',
    height: '3.6rem',
    lineHeight: '4.5rem',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    color: theme.colors.ui.white,
    overflow: 'hidden',
    marginBottom: '0.5rem',
    padding: 0,
  },
  navbarLogoIconContainer: { width: '3.15rem', textAlign: 'center', display: 'inline-block' },
  navbarLogoIcon: { width: '2.25rem', verticalAlign: 'middle' },
  navbarLogoTextContainer: { height: '4.5rem', width: '6.3rem', textAlign: 'left', display: 'inline-block' },
  navbarLogoText: { width: '4.05rem', verticalAlign: 'middle' },

  linksContainer: {
    paddingTop: 0,
    paddingBottom: 0,
  },

  navbarLink: {
    textDecoration: 'none',
    height: '2.61rem',
    display: 'flex',
    whiteSpace: 'nowrap',
    transition: 'width 0.5s',
    opacity: 0.4,
    pointerEvents: 'none',
    overflow: 'hidden',
  },
  navbarLinkOn: {
    opacity: 0.8,
    cursor: 'pointer',
    pointerEvents: 'all',
    '&:hover': {
      opacity: 1,
      fontWeight: 'bold',
      backgroundColor: theme.colors.ui.blueLight,
    },
    '&.active': {
      opacity: 1,
      fontWeight: 'bold',
    },
  },
  navbarLinkOff: {
    cursor: 'default',
    opacity: 0.4,
    pointerEvents: 'all',
  },
  navbarLinkIconContainer: { display: 'flex', minWidth: 0, marginRight: '1.175rem' },
  bottomLinkIconContainer: { marginRight: 0 },
  navbarLinkIcon: { display: 'inline-block', width: '0.99rem' },
  navbarLinkText: { fontSize: '0.63rem', color: theme.colors.ui.white, fontWeight: 'inherit' },
  bottomLink: {
    justifyContent: 'center',
  },

  circleNotification: {
    height: '20px',
    width: '20px',
    position: 'absolute',
    color: theme.colors.ui.white,
    left: '65%',
    fontSize: '0.58rem',
    marginTop: '1px',
    backgroundColor: theme.colors.ui.red,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomIcon: { width: '1.35rem', color: theme.colors.ui.white },
  thirdPartyIcon: {
    width: '0.9rem',
    height: 'auto',
  },
  navbarOpenCloseContainer: {
    height: '2.7rem',
    lineHeight: '2.7rem',
    cursor: 'pointer',
    overflow: 'hidden',
    color: theme.colors.ui.white,
    opacity: 0.8,
    '&:hover': {
      opacity: 1,
      fontWeight: 'bold',
    },
  },
  navbarOpenCloseText: {
    fontSize: '0.63rem',
  },
  upgradeMessageTooltip: {
    backgroundColor: theme.colors.ui.blueDark,
    fontSize: `${theme.fontSizes.medium} !important`,
    maxWidth: '1100px',
    opacity: '1 !important',
    pointerEvents: 'auto !important',
    '&:hover': { visibility: 'visible !important' },
  },
  externalASMUpgradeMessageTooltip: {
    top: '.1% !important',
    '&::after': {
      top: '21.5% !important',
    },
  },
  vulnerabilitiesUpgradeMessageTooltip: {
    top: '.1% !important',
    '&::after': {
      top: '32.5% !important',
    },
  },
  thirdPartyRiskUpgradeMessageTooltip: {
    top: '.1% !important',
    '&::after': {
      top: '43.5% !important',
    },
  },
  upgradeMessageContentContainer: { display: 'flex', gap: '15px' },
  upgradeMessageTextContainer: {
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr',
    gridGap: '10px',
    whiteSpace: 'normal',
    lineHeight: '1.35rem',
  },
  bulletPointContainer: { marginLeft: '1rem' },
  upgradeLinkContainer: { display: 'flex', justifyContent: 'center', paddingTop: '15px' },
  upgradeLink: {
    color: 'white',
    fontSize: `${theme.fontSizes.mediumLarge} !important`,
    fontWeight: 'bold',
    '&:hover': { color: 'white' },
    borderBottom: '1px solid white',
  },
  upgradeMessageTooltipImg: { width: '600px' },
  selectOrganisationIcon: {
    color: ({ isUserOrganisation }) => (isUserOrganisation ? theme.colors.ui.white : theme.colors.ui.gold),
    fontSize: '1.2rem',
  },
  profileIcon: { color: theme.colors.ui.white },
  pocUpgradeLink: {
    fontSize: '1rem',
    color: theme.colors.ui.goldLight,
    '&:hover': {
      color: theme.colors.ui.gold,
      textDecoration: 'underline',
    },
  },
  subscriptionDialog: {
    marginLeft: '144px',
    zIndex: '-1',
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the opacity and color as needed
  },
})

// ========
// QUERIES
// ========
const QUERY_USER_ALERT_SENT = GQL`
  query userAlertsSent {
    userAlertsSent(alertTypeIds:[1,2,3,4,5,6]) {
      totalUnacknowledgedCount
      totalCount
    }
}

`

const QUERY_ORGANISATION_COMPANIES_DATA = GQL`
  query organisationCompaniesData($removeOrganisationCompany: Boolean, $removeUnprocessedCompanies: Boolean) {
    companies(
      tags: [], 
      countries: [],
      sectors: [],
      companies: [], 
      cves: [], 
      duration: {}, 
      score: {}, 
      orderBy: {field: name, direction: ASC}
      removeOrganisationCompany: $removeOrganisationCompany
      removeUnprocessedCompanies: $removeUnprocessedCompanies
    ) {
      totalCount
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
const MyNavLink = React.forwardRef<any, any>((props, ref) => (
  <NavLink ref={ref} {...props}>
    {props.children}
  </NavLink>
))

const Navbar: React.FC = () => {
  const { enqueueSnackbar } = SnackBar.Consumer()
  const uiState = useSelector((state) => state.ui)
  const { pocExpiresInDays } = useSelector((state) => state.auth)
  const isNavbarOpen = uiState.isNavbarOpen
  const routes = getRoutesFromPayload()
  const { enableCveAccess, userId, subscriptionPlan } = getJwtPayload()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const {
    viewingAsThirdParty,
    thirdPartySwitchEnabled,
    tenantSwitchEnabled,
    userOrganisationId,
    organisationId,
    userOrganisationThirdPartySwitchEnabled,
  } = getJwtPayload()
  const isUserOrganisation = userOrganisationId === organisationId
  const isAlertDrawerOpen = getAlertDrawerOpenStateFromUrl()
  const { thirdPartyCompanyName, showThirdPartyCompanyChange } = useSelector((state) => state.ui)

  //modal & drawer hooks
  const [globalSearchModalOpen, setGlobalSearchModalOpen] = useState(false)
  const [isOrgModalOpen, setIsOrgModalOpen] = useState(false)
  const [companySelectorOpen, setCompanySelectorOpen] = useState(false)
  const [isProfilePopoverOpen, setIsProfilePopoverOpen] = useState(false)
  const [anchorElProfileIcon, setAnchorElProfileIcon] = useState<HTMLElement>(null)
  const [cveModalOpen, setCveModalOpen] = useState(false)
  const [previousPathname, setPreviousPathname] = useState(location.pathname)
  const [route, setRoute] = useState({
    to: location.pathname + location.search,
    from: location.pathname + location.search,
  })
  const subscriptionEnabled = Boolean(routes && routes['/subscription'])
  const handleToggleProfilePopover = () => setIsProfilePopoverOpen(!isProfilePopoverOpen)

  //data hooks
  const [cveId, setCveId] = useState('')
  const [organisationCompanyData, setOrganisationCompanyData] = useState([])
  const [totalUnacknowledgedCount, setTotalUnacknowledgedCount] = useState(0)
  // subscription hooks
  const [isLoading, setLoading] = useState(false)
  const [isSubscriptionOpen, setIsSubscriptionOpen] = useState(false)
  const [step, setStep] = useState('subscription-info')
  const handleSetStep = (step: string) => setStep(step)

  const handleOnClickPremiumAccess = async (state: boolean) => {
    setLoading(true)
    try {
      const { data } = await axios.post('/payment-portal-lite/products', {
        userId,
      })
      dispatch({
        type: 'SUBSCRIPTION_PACKAGES_INFO',
        payload: {
          customerId: data?.customerId,
          email: data?.email,
          products: data?.products,
        },
      })
      handleSetStep('subscription-info')
      setIsSubscriptionOpen(state)
      // history.push('/your-report/upgrade')
      // }
      recordUserInteraction(selfUpgradeModalOpen)
    } catch (error) {
      console.log('handle submit code and email error: ', error)
      if (
        error?.response?.data?.message === `Please contact us at support@orpheus-cyber.com to upgrade` ||
        error?.response?.data?.message === 'Referral code not recognised'
      ) {
        enqueueSnackbar(error?.response?.data?.message, {
          variant: 'error',
        })
      } else
        enqueueSnackbar('Something went wrong, please try again later', {
          variant: 'error',
        })
    } finally {
      setLoading(false)
    }
  }

  const handleLinkClick = (e: Event, name: string) => {
    e.preventDefault()
    handleOnClickPremiumAccess(true)
    recordUserInteraction(userClickedOnUpgradeNavbar(name))
  }
  //query data
  const {
    data: userAlertData,
    error: userAlertError,
    refetch: refetchUserAlerts,
    loading,
  } = useQuery(QUERY_USER_ALERT_SENT, {
    pollInterval: 300000, // notifications every 5 minutes
    fetchPolicy: 'no-cache',
  })
  useEffect(() => {
    refetchUserAlerts()
  }, [uiState.notificationChange])

  const {
    loading: companyDataLoading,
    error: companyDataError,
    data: companyData,
  } = useQuery(QUERY_ORGANISATION_COMPANIES_DATA, {
    variables: { removeOrganisationCompany: true, removeUnprocessedCompanies: true },
  })

  const getPOCUpgradeMessage: React.FC<{ subscriptionEnabled: boolean }> = ({ subscriptionEnabled }) => {
    const href = subscriptionEnabled ? '/subscription' : 'mailto:sales@orpheus-cyber.com'
    const linkText = subscriptionEnabled ? 'Upgrade' : 'Contact us'
    return (
      <>
        <Link
          href={href}
          color="secondary"
          target="_blank"
          rel="noopener noreferrer"
          underline="none"
          className={classes.pocUpgradeLink}
        >
          {linkText}
        </Link>
        {subscriptionEnabled ? <>&nbsp;now to secure your access. </> : <>&nbsp;to upgrade your subscription. </>}
      </>
    )
  }

  const pocEnqueueSnackbarMessage = (pocExpiresInDays: number | null, subscriptionEnabled: boolean) => {
    enqueueSnackbar(
      <>
        You have {pocExpiresInDays} {pocExpiresInDays === 1 ? 'day' : 'days'} left on your trial.&nbsp;
        {getPOCUpgradeMessage({ subscriptionEnabled: subscriptionEnabled })}
      </>,
      {
        autoHideDuration: 8000,
        ContentProps: { style: { backgroundColor: '#03123E' } },
      }
    )
  }

  useEffect(() => {
    const urlParams = window.location.search
    const params = new URLSearchParams(urlParams)
    const getCve = params.get('open-cve')

    if (getCve) {
      handleCveModalOpenState(true)
      handleSetCveId(getCve)
    } else {
      setCveModalOpen(false)
    }
  }, [location])

  useEffect(() => {
    setPreviousPathname(location.pathname + location.search)
    setRoute((prev) => {
      return { to: location.pathname + location.search, from: prev.to }
    })
  }, [location])

  useEffect(() => {
    persistor.flush().then(() => {
      //if poc <1 then it means it has already expired, and if pocExpiresInDays is null then it means its not on poc trial
      if (pocExpiresInDays && pocExpiresInDays >= 1 && uiState.justLoggedInStatus) {
        pocEnqueueSnackbarMessage(pocExpiresInDays, subscriptionEnabled)
        dispatch({
          type: 'JUST_LOGGED_IN_STATUS',
          payload: { justLoggedInStatus: false },
        })
      }
    })
  }, [])

  useEffect(() => {
    isAlertDrawerOpen && history.push('/alerts')
  }, [isAlertDrawerOpen])

  useEffect(() => {
    setAnchorElProfileIcon(document.getElementById('account-icon-container'))
  }, [])

  const classes = useStyles({
    isUserOrganisation,
  })

  useEffect(() => {
    return history.listen(() => {
      handleCloseOrgModal()
      setCompanySelectorOpen(false)
      setGlobalSearchModalOpen(false)
    })
  }, [history])

  useEffect(() => {
    if (companyData) {
      const companyList = companyData.companies.edges.map(({ node }) => node)
      setOrganisationCompanyData(companyList)

      if (showThirdPartyCompanyChange && viewingAsThirdParty) {
        enqueueSnackbar(
          <>
            <img src={ThirdPartyIconActive} className={classes.thirdPartyIcon} style={{ marginRight: '3px' }} /> Viewing
            third party company: {thirdPartyCompanyName}{' '}
          </>,
          {
            autoHideDuration: 15000,
            ContentProps: { style: { backgroundColor: '#03123E' } },
          }
        )
        recordUserInteraction(userViewingAsThirdPartyCompany)
        dispatch({
          type: 'SHOW_THIRD_PARTY_COMPANY_CHANGED',
          payload: { showThirdPartyCompanyChange: false },
        })
      }
    }
  }, [companyData])

  useEffect(() => {
    if (userAlertData?.userAlertsSent?.totalCount) {
      setTotalUnacknowledgedCount(userAlertData.userAlertsSent.totalUnacknowledgedCount)
    }
  }, [userAlertData])

  //Event handler functions
  const handleCloseOrgModal = () => setIsOrgModalOpen(false)
  const handleSetCveId = (cveId: string) => setCveId(cveId)

  const handleCveModalOpenState = (state: boolean) => {
    setCveModalOpen(state)
    let path = ''
    const cveRegex = /CVE-\d{4}-\d{4,7}/gim
    if (!state && cveModalOpen) {
      if (route.from === location.pathname + location.search) {
        path = location.pathname + location.search
      } else {
        // if route is redirected from old route
        path = route.from.includes('/cve') ? previousPathname : route.from
      }

      const removeCveIdFromUrl = removeParamAndReturnUrl('open-cve', path)
      history.push(removeCveIdFromUrl)
    }
  }

  const handleCloseGlobalSearchModal = () => setGlobalSearchModalOpen(false)

  const handleTopNavLinkClick = (event: MouseEvent, tab: string) => {
    recordUserInteraction(userClickedOnPageLink(tab.slice(1)))
    if (!routes[tab]) {
      event.preventDefault()
    }
  }

  const handleBottomNavClick = (tab: string) => {
    const selectedOrg = tab === 'Selected Organisation'
    const alerts = tab === 'Alerts' && !viewingAsThirdParty && isUserOrganisation && routes['/alerts']
    const selectedCompany = tab === 'Selected Company'
    const search = tab === 'Search' && enableCveAccess
    //toggle modals and drawer
    setIsOrgModalOpen(selectedOrg ? !isOrgModalOpen : false)
    setCompanySelectorOpen(selectedCompany ? !companySelectorOpen : false)
    setGlobalSearchModalOpen(search ? !globalSearchModalOpen : false)
    recordUserInteraction(userClickedOnNavbarBottomIcons(tab))
  }

  const handleOpenCloseNavbar = (e: React.MouseEvent<HTMLInputElement>): void => {
    e.preventDefault()
    if (isNavbarOpen) {
      recordUserInteraction(userToggledNavBar('closed'))
      dispatch({
        type: 'CLOSE_NAVBAR',
      })
    } else {
      recordUserInteraction(userToggledNavBar('opened'))
      dispatch({
        type: 'OPEN_NAVBAR',
      })
    }
  }

  return (
    <>
      <div className={classes.root} id="navbar">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: isNavbarOpen,
            [classes.drawerClose]: !isNavbarOpen,
          })}
          classes={{
            paper: clsx(classes.paper, {
              [classes.drawerOpen]: isNavbarOpen,
              [classes.drawerClose]: !isNavbarOpen,
            }),
          }}
        >
          <List className={classes.linksContainer}>
            <ListItem
              className={classes.navbarLogoLink}
              classes={{ root: classes.linksContainer }}
              component={MyNavLink}
              onClick={() => recordUserInteraction(userClickedOrpheusLogo)}
              to="/external-asm"
            >
              <div className={classes.navbarLogoIconContainer}>
                <img className={classes.navbarLogoIcon} src={navbarLogoImage} />
              </div>
              <div className={classes.navbarLogoTextContainer}>
                <img className={classes.navbarLogoText} src={navbarLogoText} />
              </div>
            </ListItem>
            {navbarTopPages.map(({ path, name, icon, Icon }) => {
              const dontShowTooltipUpgradeMessage = name === 'Your Report' || name === 'Threat Analysis'
              const alertsToolTip = name === 'Alerts'
              const { bulletPoints } = !alertsToolTip && !dontShowTooltipUpgradeMessage && mapTooltipPageSummary[name]
              const isUpgradeToolTipEnabled = !!(!routes || routes[path])
              const hasRoutes = routes && routes[path]
              const isAlert = name === 'Alerts'
              const showAlert = isAlert && routes && routes['/alerts'] && !viewingAsThirdParty
              const isLinkOff = !routes[path] || (isAlert && !showAlert)

              if (path === '/threat' && (!routes || !routes[path])) return null
              if (path === '/your-report' && (!routes || !routes[path])) return null
              const tooltipText = getTooltipText(
                name,
                viewingAsThirdParty,
                thirdPartyCompanyName,
                isUserOrganisation,
                routes,
                organisationId
              )
              return (
                <Fragment key={path + name}>
                  <ListItem
                    key={path}
                    data-tip
                    data-for={`tooltip-${path}`}
                    className={clsx(classes.navbarLink, isLinkOff ? classes.navbarLinkOff : classes.navbarLinkOn)}
                    component={MyNavLink}
                    onClick={(event: MouseEvent) => handleTopNavLinkClick(event, path)}
                    to={path}
                  >
                    <ListItemIcon className={classes.navbarLinkIconContainer}>
                      {path === '/alerts' ? (
                        <Icon width={18} className={classes.selectOrganisationIcon} />
                      ) : (
                        <img className={classes.navbarLinkIcon} src={icon} />
                      )}
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.navbarLinkText }} primary={name}></ListItemText>
                    {showAlert && totalUnacknowledgedCount ? (
                      //use notification count from state ui reducer
                      <span className={classes.circleNotification}>
                        {humanFormat(totalUnacknowledgedCount, {
                          maxDecimals: 0,
                        })}
                      </span>
                    ) : undefined}
                  </ListItem>
                  {dontShowTooltipUpgradeMessage ? null : (
                    <ReactTooltip
                      disable={routes[path] && !tooltipText}
                      backgroundColor={theme.colors.ui.blueDark}
                      id={`tooltip-${path}`}
                      place="right"
                      effect="solid"
                      className={
                        isUpgradeToolTipEnabled
                          ? null
                          : clsx(classes.upgradeMessageTooltip, {
                              [classes.externalASMUpgradeMessageTooltip]: name === 'External ASM',
                              [classes.vulnerabilitiesUpgradeMessageTooltip]: name === 'Internal RBVM',
                              [classes.thirdPartyRiskUpgradeMessageTooltip]: name === 'Third Party Risk',
                            })
                      }
                      delayHide={20}
                    >
                      {(alertsToolTip && viewingAsThirdParty) || isUpgradeToolTipEnabled ? (
                        tooltipText
                      ) : (
                        <div className={classes.upgradeMessageContentContainer}>
                          <img src={mapTooltipPageImages[name]} className={classes.upgradeMessageTooltipImg} />
                          <div className={classes.upgradeMessageTextContainer}>
                            <h2>{name}</h2>
                            <ul className={classes.bulletPointContainer}>
                              {bulletPoints?.map((bulletPoint) => (
                                <li style={{ listStyleType: 'disc' }}>{bulletPoint}</li>
                              ))}
                            </ul>
                            <div className={classes.upgradeLinkContainer}>
                              {isLoading ? (
                                <Loaders.Spinner size={18} />
                              ) : (
                                <span>
                                  <a
                                    href="mailto:sales@orpheus-cyber.com"
                                    target="_blank"
                                    className={classes.upgradeLink}
                                    // onClick={userId && !subscriptionPlan?.type ? (e) => handleLinkClick(e, name) : null}
                                    onClick={(e) => handleLinkClick(e, name)}
                                  >
                                    Upgrade
                                  </a>{' '}
                                  your subscription to access this page.
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </ReactTooltip>
                  )}
                </Fragment>
              )
            })}
          </List>
          <List className={classes.linksContainer}>
            {navbarBottomPages.map(({ name, Icon }, index) => {
              const isAlert = name === 'Alerts'
              const isSearch = name === 'Search'
              const isSelectedCompany = name === 'Selected Company'
              const isSelectedOrganisation = name === 'Selected Organisation'

              const showAlert = isAlert && routes && routes['/alerts'] && isUserOrganisation && !viewingAsThirdParty
              const showSearch = isSearch && enableCveAccess

              const isLinkOff = isSearch && !showSearch

              const tooltipText = getTooltipText(
                name,
                viewingAsThirdParty,
                thirdPartyCompanyName,
                isUserOrganisation,
                routes,
                organisationId
              )
              if (
                (!thirdPartySwitchEnabled && isUserOrganisation && isSelectedCompany) ||
                (!userOrganisationThirdPartySwitchEnabled && !isUserOrganisation && isSelectedCompany) ||
                (!tenantSwitchEnabled && isSelectedOrganisation)
              ) {
                return null
              }

              return (
                <Fragment key={index}>
                  <ListItem
                    button
                    key={name}
                    data-tip
                    data-for={`tooltip-${index}`}
                    className={clsx(
                      classes.navbarLink,
                      classes.bottomLink,
                      isLinkOff ? classes.navbarLinkOff : classes.navbarLinkOn
                    )}
                    onClick={() => handleBottomNavClick(name)}
                  >
                    <ListItemIcon
                      className={[classes.navbarLinkIconContainer, classes.bottomLinkIconContainer].join(' ')}
                    >
                      {isSelectedCompany ? (
                        <img
                          src={viewingAsThirdParty ? ThirdPartyIconActive : ThirdPartyIconWhite}
                          className={classes.thirdPartyIcon}
                        />
                      ) : (
                        <Icon
                          width={18}
                          className={isSelectedOrganisation ? classes.selectOrganisationIcon : classes.bottomIcon}
                        />
                      )}
                      {showAlert && totalUnacknowledgedCount && isUserOrganisation ? (
                        //use notification count from state ui reducer
                        <span className={classes.circleNotification}>
                          {humanFormat(totalUnacknowledgedCount, { maxDecimals: 0 })}
                        </span>
                      ) : undefined}
                    </ListItemIcon>
                  </ListItem>

                  <ReactTooltip
                    disable={!tooltipText}
                    backgroundColor={theme.colors.ui.blueDark}
                    id={`tooltip-${index}`}
                    place="right"
                    effect="solid"
                  >
                    {tooltipText}
                  </ReactTooltip>
                </Fragment>
              )
            })}
            <ListItem
              button
              id={'account-icon-container'}
              onMouseEnter={() => setIsProfilePopoverOpen(true)}
              onMouseLeave={() => setIsProfilePopoverOpen(false)}
              onClick={() => handleToggleProfilePopover()}
              className={clsx(classes.navbarLink, classes.navbarLinkOn, classes.bottomLink)}
            >
              <ListItemIcon className={[classes.navbarLinkIconContainer, classes.bottomLinkIconContainer].join(' ')}>
                <AccountCircleOutlinedIcon className={classes.bottomIcon} width={18} />
              </ListItemIcon>
            </ListItem>

            <ListItem
              button
              key={'collapse'}
              onClick={handleOpenCloseNavbar}
              className={classes.navbarOpenCloseContainer}
            >
              <ListItemIcon className={classes.navbarLinkIconContainer}>
                {isNavbarOpen ? <ArrowLeftIcon width={18} /> : <ArrowRightIcon width={18} />}
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.navbarOpenCloseText }} primary={'Collapse'} />
            </ListItem>
          </List>
        </Drawer>
        {/* user profile popover */}
        <UserProfile
          isOpen={isProfilePopoverOpen}
          anchorElProfile={anchorElProfileIcon}
          isNavbarOpen={isNavbarOpen}
          viewingAsThirdParty={viewingAsThirdParty}
          setIsProfilePopoverOpen={(state: boolean) => setIsProfilePopoverOpen(state)}
          routes={routes}
        />
        {/* company selector drawer */}

        <SelectCompany
          open={companySelectorOpen}
          onClose={() => setCompanySelectorOpen(false)}
          organisationCompanyData={organisationCompanyData}
          loading={companyDataLoading}
          error={Boolean(companyDataError)}
        />
      </div>
      {isOrgModalOpen && (
        <SelectOrganisationModal
          onClose={handleCloseOrgModal}
          isUserOrganisation={isUserOrganisation}
          organisationId={organisationId}
        />
      )}
      {globalSearchModalOpen && (
        <GlobalSearchModal
          onClose={handleCloseGlobalSearchModal}
          handleSetCveId={handleSetCveId}
          handleCveModalOpenState={handleCveModalOpenState}
          routes={routes}
        />
      )}
      {cveModalOpen && (
        <CveModal cveIdFromSearch={cveId} onClose={() => handleCveModalOpenState(false)} closeOnOutsideClick />
      )}
      {isSubscriptionOpen && (
        <Dialog
          className={classes.subscriptionDialog}
          open={isSubscriptionOpen}
          onClose={() => handleOnClickPremiumAccess(false)}
          maxWidth={'lg'}
          aria-labelledby="subscription-info-dialog-title"
        >
          <SelfSignUpLandingPage step={step} handleSetStep={handleSetStep} />
        </Dialog>
      )}
    </>
  )
}

export default hot(Navbar)
